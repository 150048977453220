import AuthenticationContext from './adal'
/**
 * adal for Vue.js
 * @since 2018/10/11 16:44:53
 */
export default {
  // 实例
  authenticationContext: null,
  // 配置项
  config: null,
  // 初始化方法
  async initialize(config) {
    // 注入配置实例化
    this.authenticationContext = new AuthenticationContext(config)
    this.config = config
    window.AuthenticationContext = AuthenticationContext
    window.__eso__ = this.authenticationContext // For Debugger
    return new Promise((resolve, reject) => {
      if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
        this.authenticationContext.handleWindowCallback()
      } else {
        if (process.env.NODE_ENV === 'production') {
          const user = this.authenticationContext.getCachedUser()
          if (user) {
            resolve()
          } else {
            this.signIn()
          }
        } else {
          resolve()
        }
      }
    })
  },
  // 获取token
  acquireToken() {
    return new Promise((resolve, reject) => {
      this.authenticationContext.acquireToken(this.config.clientId, (error, token) => {
        if (error || !token) {
          return reject(error)
        } else {
          return resolve(token)
        }
      })
    })
  },
  // 获取token重定向
  acquireTokenRedirect() {
    this.authenticationContext.acquireTokenRedirect(this.config.redirect_uri)
  },
  // 是否验证过
  isAuthenticated() {
    return !!this.authenticationContext.getCachedToken(this.config.clientId)
  },
  // 获取用户配置文件
  getUserProfile() {
    return this.authenticationContext.getCachedUser().profile
  },
  // 登录
  signIn() {
    this.authenticationContext.login()
  },
  // 登出
  signOut() {
    this.authenticationContext.logOut()
  },
  getCachedUser() {
    return this.authenticationContext.getCachedUser()
  }
}
